import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { InsuranceService } from '@app/services/insurance.service';
import { UserService } from '@app/services/user.service';
import { validateFiles } from '@progress/kendo-angular-upload';
import { PasswordValidators } from '@shared/utility/password-validators';
import { ToasterService } from '@shared/utility/toaster.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent implements OnInit {
  private subscription = new Subscription();
  changePasswordForm: FormGroup;
  message: string;
  loading: boolean = false;
  showPassword :boolean=false;
  showNewPassword :boolean=false;
  showConfirmPassword:boolean=false;
  submitted = false;

  constructor(
    private userservice: UserService,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    public oidcSecurityService: OidcSecurityService,
    private router: Router,
    private fb: FormBuilder,
    public authService: AuthService,
  ) { }
  ngOnInit(): void {
    
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ["", [Validators.required, Validators.minLength(8),
        Validators.maxLength(50),
        this.userservice.noWhitespaceValidator,
         Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}'), 
          PasswordValidators.patternValidator(new RegExp("(?=.*[0-9])"), {
            requiresDigit: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[A-Z])"), {
            requiresUppercase: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[a-z])"), {
            requiresLowercase: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[(!%&@#$^*?_~)“])"), {
            requiresSpecialChars: true
          }),
        ]],
        // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}')]],
      confirmPassword: ["", [Validators.required, Validators.maxLength(50), Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}')]],
    }, 
    {
      validator: PasswordValidators.ChangePasswordMatchValidator
    });
    
  }
  // passwordMatchValidator(g: FormGroup) {
  //   return g.get('newPassword').value === g.get('confirmPassword').value ? null : { 'mismatch': true };
  // }
  onSubmit() {
    if (this.changePasswordForm.valid) {
      this.userservice.changePassword(this.changePasswordForm.value).subscribe(
        response => {
          if (response.success) {
            this.message = 'password changed successfully your re login again!';
            this.toasterService.success(this.message, 'Success');
            this.changePasswordForm.reset();
            setTimeout(() => {
              // if (confirm(`Are you sure you want to logout`)) {
                this.authService.localLogout();
                this.oidcSecurityService.logoff().subscribe((result) => this.router.navigateByUrl('/'));
                window.sessionStorage.clear();
                window.localStorage.clear();
                this.router.navigate(['autologin']);
              //}
            }, 5000);
          }
          else {
            this.toasterService.info(response.payload);
          }
        },
        (error) => console.log('HTTP Error', error),
        () => this.loading = false
      );
    }
  }

  
  resetForm() {
    this.changePasswordForm.reset();
    this.message = '';
  }
  get f() {
    return this.changePasswordForm.controls;
  }
  get passwordValid() {
    return this.changePasswordForm.controls["newPassword"].errors === null;
  }

  get requiredValid() {
    return !this.changePasswordForm.controls["newPassword"].hasError("required");
  }

  get minLengthValid() {
    return !this.changePasswordForm.controls["newPassword"].hasError("minlength");
  }
  get maxLengthValid() {
    return !this.changePasswordForm.controls["newPassword"].hasError("maxlength");
    
  }
  get requiresDigitValid() {
    return !this.changePasswordForm.controls["newPassword"].hasError("requiresDigit");
  }
  get noWhiteSPaceValid() {
    return !this.changePasswordForm.controls["newPassword"].hasError("requiresDigit");
  }
  get requiresUppercaseValid() {
    return !this.changePasswordForm.controls["newPassword"].hasError("requiresUppercase");
  }

  get requiresLowercaseValid() {
    return !this.changePasswordForm.controls["newPassword"].hasError("requiresLowercase");
  }

  get requiresSpecialCharsValid() {
    return !this.changePasswordForm.controls["newPassword"].hasError("requiresSpecialChars");
  }
  togglePasswordVisibility():void
  {
this.showPassword=!this.showPassword;
  }
  toggleNewPasswordVisibility():void
  {
this.showNewPassword=!this.showNewPassword;
  }
  toggleConfirmPasswordVisibility():void
  {
this.showConfirmPassword=!this.showConfirmPassword;
  }
}
