<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Change Password</h4>
  </div>
  <form novalidate [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-6 changePass-group">
          <label>Old Password</label>
          <div class="position-relative d-flex align-items-center justify-content-center flex-wrap">
          <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control" formControlName="oldPassword" maxlength="50" required>
          <span *ngIf="!showPassword" class="material-symbols-outlined cursor-pointer text-primary password-icon-two" (click)="togglePasswordVisibility()">
            visibility
           </span>
           <span *ngIf="showPassword" class="material-symbols-outlined cursor-pointer text-primary password-icon-two" (click)="togglePasswordVisibility()">
             visibility_off
            </span>
          </div>
          <div class="validation-msg-label"
            *ngIf="changePasswordForm.controls?.oldPassword.touched  && changePasswordForm.controls?.oldPassword.errors?.required">
            Required
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 changePass-group">
          <label>New Password</label>
          <div class="position-relative d-flex align-items-center justify-content-center flex-wrap"> 
          <input type="{{ showNewPassword ? 'text' : 'password' }}"  class="form-control" formControlName="newPassword" maxlength="50" required
          [ngClass]="{ 'is-valid': (f.newPassword.dirty || submitted) && passwordValid, 'is-invalid': (f.newPassword.dirty || submitted) && !passwordValid }">
      
          <span *ngIf="!showNewPassword" class="material-symbols-outlined cursor-pointer text-primary password-icon-two" (click)="toggleNewPasswordVisibility()">
            visibility
           </span>
           <span *ngIf="showNewPassword" class="material-symbols-outlined cursor-pointer text-primary password-icon-two" (click)="toggleNewPasswordVisibility()">
             visibility_off
            </span>
            <div class="invalid-feedback">
              <!-- required -->
              <span class="text-danger" *ngIf="(f.newPassword.dirty || submitted) && f.newPassword.errors && !requiredValid">Password is required</span>
  
              <!-- password validation rules -->
              <div id="validation-rules" class="mt-0 " *ngIf="requiredValid">
                <!-- minlength -->
                <div [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }">
                  <i [attr.class]="minLengthValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                  Must be at least 8 characters long
                </div>
                <div [ngClass]="{ 'text-success': maxLengthValid, 'text-danger': !maxLengthValid }">
                  <i [attr.class]="minLengthValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                  Must be less than 51 characters
                </div>
                 <!-- requires digit -->
                 <div [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }">
                  <i [attr.class]="requiresDigitValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                  Must contain at least one digit
                </div>
                <!-- requires uppercase -->
                <div [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }">
                  <i [attr.class]="requiresUppercaseValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                  Must contain at least one uppercase character
                </div>
                <!-- requires lowercase -->
                <div [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }">
                  <i [attr.class]="requiresLowercaseValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                  Must contain at least one lowercase character
                </div>
  
                <!-- requires special characters -->
                <div [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }">
                  <i [attr.class]="requiresSpecialCharsValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                  Must contain at least one special character
                </div>
                <div *ngIf="f.newPassword.errors?.whitespace">Whitespace not allowed</div>

          </div>  
          </div>
          <!-- <div class="validation-msg"
            *ngIf="changePasswordForm.controls?.newPassword.touched  && changePasswordForm.controls?.newPassword.errors?.required">
            Required
          </div>
          <div class="validation-msg"
            *ngIf="changePasswordForm.controls?.newPassword.touched  && changePasswordForm.controls?.newPassword.errors?.maxlength">
            Max length 20.
          </div> -->
          <!-- <div class="validation-msg"
            *ngIf="changePasswordForm.controls?.newPassword.touched  && changePasswordForm.controls?.newPassword.errors?.minlength">
            Min length should be 8.
          </div> -->
          <!-- <div class="validation-msg"
            *ngIf="changePasswordForm.controls?.newPassword.touched  && changePasswordForm.controls?.newPassword.errors?.pattern">
            Password must have at least 1 character alphanumeric with uppercase and lowercase and contains special
            character.
          </div> -->
        </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 changePass-group">
          <label>Confirm Password</label>
          <div class="position-relative d-flex align-items-center justify-content-center flex-wrap">
           
            <input type="{{(showConfirmPassword == false)?'password':'text'}}" id="confirmPassword" name="confirmPassword" formControlName="confirmPassword"
            class="form-control " value=""  maxlength="50"
            [ngClass]="{ 'is-valid': (submitted || f.confirmPassword.dirty) && !f.confirmPassword.errors, 'is-invalid': (submitted || f.confirmPassword.dirty) && f.confirmPassword.errors }">
            <span *ngIf="!showConfirmPassword" class="material-symbols-outlined cursor-pointer text-primary password-icon-two" (click)="toggleConfirmPasswordVisibility()">
            visibility
           </span>
           <span *ngIf="showConfirmPassword" class="material-symbols-outlined cursor-pointer text-primary password-icon-two" (click)="toggleConfirmPasswordVisibility()">
             visibility_off
            </span>
            <div class="invalid-feedback" *ngIf="(submitted || f.confirmPassword.dirty) && f.confirmPassword.errors">
              <div *ngIf="f.confirmPassword.errors.required">Confirm your password</div>
              <div *ngIf="f.confirmPassword.errors.minLength">Password must be at least 8 characters long</div>
              <div *ngIf="f.confirmPassword.errors.mismatch">Passwords do not match</div>
              <div *ngIf="f.confirmPassword.errors.whitespace">Whitespace not allowed</div>
            </div>
          </div>
          <!-- <div class="validation-msg"
            *ngIf="changePasswordForm.controls.confirmPassword.touched  && changePasswordForm.controls.confirmPassword.errors?.required">
            Required
          </div> -->
          <!-- <div class="validation-msg"
            *ngIf="changePasswordForm.controls?.confirmPassword.touched  && changePasswordForm.controls?.confirmPassword.errors?.maxlength">
            Max length 20.
          </div>
          <div class="validation-msg"
            *ngIf="changePasswordForm.controls?.confirmPassword.touched  && changePasswordForm.controls?.confirmPassword.errors?.minlength">
            Min length should be 8.
          </div>
          <div class="validation-msg"
            *ngIf="changePasswordForm.controls?.confirmPassword.touched  && changePasswordForm.controls?.confirmPassword.errors?.pattern">
            Password must have at least 1 character alphanumeric with uppercase and lowercase and contains special
            character.
          </div>
          <div class="validation-msg" style="display: block;"
            *ngIf="(changePasswordForm.get('confirmPassword').dirty && changePasswordForm.hasError('mismatch'))&&(!changePasswordForm.controls?.confirmPassword.errors?.pattern)">
            Confirm password must match password
          </div> -->
        </div>
      </div>
      <div class="col-md-auto col-auto">
        <button type="submit" class="btn btn-primary " [disabled]="!changePasswordForm.valid">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
          Change Password
        </button>
        <button type="button" class="btn btn-secondary ms-2" (click)="resetForm()">
          Reset
        </button>
      </div>
      </div>
      
  </form>
  <!-- <div class="modal-footer"></div> -->
</div>