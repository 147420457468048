<div class="modal-header">
    <h4 class="modal-title">User Profile</h4>
</div>
<form novalidate [formGroup]="userprofileForm">
    <div class="modal-body overflow-inherit">
        <div class="row">
            <div class="form-group col-md-6">
                <label>First Name</label>
                <input type="text" class="form-control" formControlName="firstName" maxlength="50" minlength="2"
                    nameOnly appSpaceInputRestriction>
                <div class="validation-msg-label"
                    *ngIf="userprofileForm.controls.firstName.touched  && userprofileForm.controls.firstName.errors?.required">
                    Required
                </div>
                <div class="validation-msg-label"
                    *ngIf="userprofileForm.controls.firstName.touched  && userprofileForm.controls.firstName.errors?.whitespace">
                    Invalid First Name
                </div>
            </div>

            <div class="form-group col-md-6">
                <label>Middle Name</label>
                <input type="text" class="form-control" formControlName="middleName" maxlength="50" nameOnly appSpaceInputRestriction/>
                <div class="validation-msg-label"
                    *ngIf="userprofileForm.controls.middleName.touched  && userprofileForm.controls.middleName.errors?.whitespace">
                    Invalid Middle Name
                </div>
            </div>

            <div class="form-group col-md-6">
                <label>Last Name</label>
                <input type="text" class="form-control" formControlName="lastName" maxlength="50" minlength="2"
                    nameOnly appSpaceInputRestriction/>
                <div class="validation-msg-label"
                    *ngIf="userprofileForm.controls.lastName.touched  && userprofileForm.controls.lastName.errors?.required">
                    Required
                </div>
                <div class="validation-msg-label"
                    *ngIf="userprofileForm.controls.lastName.touched  && userprofileForm.controls.lastName.errors?.whitespace">
                    Invalid Last Name
                </div>
            </div>

            <div class="form-group col-md-6">
                <label>Username</label>
                <input type="text" class="form-control" formControlName="userName" readonly>
            </div>

            <div class="form-group col-md-6">
                <label>Email Address</label>
                <input type="text" class="form-control" formControlName="emailAddress" min="2" maxlength="50">
                <div class="validation-msg-label"
                    *ngIf="userprofileForm.controls.emailAddress.touched  && userprofileForm.controls.emailAddress.errors?.required">
                    Required
                </div>
                <div class="validation-msg-label" *ngIf="!userprofileForm.controls.emailAddress.errors?.required &&
                                        userprofileForm.controls.emailAddress.invalid">Invalid Email
                </div>
            </div>

            <div class="form-group col-md-6">
                <label>Agent Name</label>
                <input type="text" class="form-control" formControlName="tradingName" readonly>
            </div>

            <div class="form-group col-md-6">
                <label>Mobile Number</label>
                <div class="mobile-ddmenu">
                    <ngx-intl-tel-input [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Ireland"
                        [maxLength]="13" [phoneValidation]="true" [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.National" formControlName="mobile"
                        (ngModelChange)="onContactChange()">
                    </ngx-intl-tel-input>
                    <div class="validation-msg-label"
                        *ngIf="userprofileForm.controls.mobile.touched  && userprofileForm.controls.mobile.errors?.required">
                        Required
                    </div>
                    <div class="validation-msg-label"
                        *ngIf="userprofileForm.controls.mobile?.touched &&  !userprofileForm.controls.mobile?.errors?.required && userprofileForm.controls.mobile?.errors?.invalid">
                        Invalid Phone
                    </div>
                    <div class="validation-msg-label" *ngIf="this.isInvalidContact">
                        Invalid Phone
                    </div>
                </div>
                <!-- <div class="mobile-ddmenu">
                    <ngx-intl-tel-input [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" class=" col-md-12"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Ireland" [maxLength]="13"
                        [phoneValidation]="false" [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.National" formControlName="mobile"
                        (ngModelChange)="onContactChange()">
                        
                    </ngx-intl-tel-input>
                    <div class="validation-msg-label"
                        *ngIf="userprofileForm.controls.mobile.touched  && userprofileForm.controls.mobile.errors?.required">
                        Required
                    </div>
                    <div class="validation-msg-label"
                        *ngIf="userprofileForm.controls?.mobile?.touched &&  !userprofileForm.controls?.mobile?.errors?.required && userprofileForm.controls?.mobile?.errors?.invalid">
                        Invalid Phone
                    </div>
                    <div class="validation-msg-label" *ngIf="this.isInvalidContact">
                        Invalid Phone
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-md-auto if-no-label-28">
                <button type="button" class="btn btn-primary btn-sm" (click)="saveProfile()"><i
                        class="la la-floppy-o"></i> Save</button>
            </div>
        </div>
    </div>
</form>
<div class="modal-footer"></div>