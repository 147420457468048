import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentinfoService } from '@app/services/agentinfo.service';
import { AgentsManagementService } from '@app/services/agents-management.service';
import { InsuranceService } from '@app/services/insurance.service';
import { UserService } from '@app/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@shared/utility/toaster.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Subscription } from 'rxjs';
import { PhoneNumberUtil } from "google-libphonenumber";
import { AuthService } from '@app/auth/auth.service';
import { getPhoneCountryCode, getPhoneCountryId } from '@shared/countryFlagUtility';
import { CountryCode } from 'ngx-intl-tel-input/lib/data/country-code';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrl: './userprofile.component.css'
})
@AutoUnsubscribe()
export class UserprofileComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  userprofileForm: FormGroup;
  firstName: string = '';
  middleName: string = '';
  lastName: string = '';
  userName: string = '';
  emailAddress: string = '';
  agentId: number;
  tradingName: string = '';
  mobile: string = '';
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  phoneUtil: any = PhoneNumberUtil.getInstance();
  isInvalidContact = false;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Ireland];
  //emailRegex = /^[a-zA-Z0-9_!"#$£%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
  emailRegex =/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private insuranceService: InsuranceService,
    private agentService: AgentsManagementService,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private router: Router,
    private fb: FormBuilder,
    public authService: AuthService


  ) { }
  ngOnDestroy(): void {

  }
  ngOnInit(): void {

    this.userprofileForm = this.fb.group({
      firstName: [{ value: '', disabled: false }, Validators.required],
      middleName: [{ value: '', disabled: false }],
      lastName: ['', Validators.required],
      userName: [{ value: '', disabled: true }],
      emailAddress: [{ value: '', disabled: false }, [Validators.required, Validators.pattern(this.emailRegex)]],
      agentId: [{ value: '', disabled: false }],
      tradingName: [{ value: '', disabled: true }],
      mobile: [{ value: '', disabled: false }, Validators.required],
      phoneCountryId: [''],
    });

    this.GetUserProfileInfo();
  }

  GetUserProfileInfo(): void {
    this.subscription.add(
      this.userService.GetUserProfileInfo().subscribe((data: any) => {
        this.userprofileForm.patchValue({
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          userName: data.userName,
          emailAddress: data.emailAddress,
          agentId: data.agentId,
          tradingName: data.tradingName,
          mobile: data.mobile,
          phoneCountryId: data.phoneCountryId,
        });

        // const phoneNumberUtil = PhoneNumberUtil.getInstance();
        // const number = phoneNumberUtil.parseAndKeepRawInput(data.countryCode +  data.mobile);
        // const countryCode = phoneNumberUtil.getRegionCodeForNumber(number);
        // var phone = {
        //   "number": data.mobile,
        //   "countryCode": countryCode,
        // }
        //this.userprofileForm.controls['mobile'].patchValue(phone);
        this.userprofileForm.controls['mobile'].patchValue(getPhoneCountryCode(data.phoneCountryId, data.mobile));

        if (data.agentId) {
          this.getAgentName();
        }
      })
    );
  }

  getAgentName(): void {
    const agentId = this.userprofileForm.get('agentId').value;
    if (agentId) {
      this.subscription.add(
        this.insuranceService.GetAgentName().subscribe((data: any) => {
          this.userprofileForm.patchValue({
            agentCode: data.agentCode,
            tradingName: data.text
          });
        })
      );
    }
  }

  onContactChange() {
    if (this.userprofileForm.controls["mobile"].value !== null && this.userprofileForm.controls["mobile"].value !== '' && this.userprofileForm.controls["mobile"].value !== undefined) {
      let data: any = this.userprofileForm.controls["mobile"].value;
      if (data && data.number !== "") {
        let isValid;
        try {
          isValid = this.phoneUtil.isValidNumber(
            // this.phoneUtil.parse(this.coverForm.controls["phoneNumber"].value.number.replace(/\s/g, ""),
            this.phoneUtil.parse(this.userprofileForm.controls["mobile"].value.number,
              data.countryCode),
            data.countryCode);
        }
        catch (err) {
          isValid = false;
        }

        this.isInvalidContact = !isValid;
        if (!this.isInvalidContact) {
          this.userprofileForm.controls["mobile"].setErrors(null);
        }
        else {
          this.userprofileForm.controls["mobile"].setErrors({ incorrect: true, });
        }
        this.userprofileForm.controls["mobile"].updateValueAndValidity();
      }

    } else {
      this.isInvalidContact = false;
    }
  }

  // onContactChange() {
  //   if (this.userprofileForm.controls["mobile"].value !== null && this.userprofileForm.controls["mobile"].value !== '' && this.userprofileForm.controls["mobile"].value !== undefined) {
  //     let data: any = this.userprofileForm.controls["mobile"].value;
  //     if (data && data.number !== "") {
  //       let isValid;
  //       try {
  //         isValid = this.phoneUtil.isValidNumber(
  //           // this.phoneUtil.parse(this.coverForm.controls["phoneNumber"].value.number.replace(/\s/g, ""),
  //           this.phoneUtil.parse(this.userprofileForm.controls["mobile"].value.number,
  //             data.countryCode),
  //           data.countryCode);
  //       }
  //       catch (err) {
  //         isValid = false;
  //       }

  //       this.isInvalidContact = !isValid;
  //       if (!this.isInvalidContact) {
  //         this.userprofileForm.controls["mobile"].setErrors(null);
  //       }
  //       else {
  //         this.userprofileForm.controls["mobile"].setErrors({ incorrect: true, });
  //       }
  //       this.userprofileForm.controls["mobile"].updateValueAndValidity();
  //     }

  //   } else {
  //     this.isInvalidContact = false;
  //   }
  // }

  saveProfile(): void {
    if (this.userprofileForm.valid) {
      const userProfileData = this.userprofileForm.getRawValue();

      if(userProfileData.mobile==null)
      {
        this.userprofileForm.controls["mobile"].setErrors({ require: true, });
        this.userprofileForm.controls["mobile"].updateValueAndValidity();
        return;
      }
      

      userProfileData.UserId = this.authService.authentication.userId;
      userProfileData.mobile = { number: this.userprofileForm.controls['mobile'].value.number.replace(/\s/g, "") };
      userProfileData.mobile.dialCode = this.userprofileForm.controls['mobile'].value.dialCode;
      userProfileData.phoneCountryId = getPhoneCountryId(this.userprofileForm.controls['mobile'].value);
      this.subscription.add(
        this.userService.UpdateUserProfileDetails(userProfileData).subscribe(
          (data: any): void => {
            this.toasterService.success('User profile updated successfully');
          },
          error => {
            this.toasterService.error('Failed to update user profile');
          }
        )
      );
    } else {
      this.userService.validateAllFormFields(this.userprofileForm);
    }
  }
}